import { Controller } from "@hotwired/stimulus";
import { parse } from "@plussub/srt-vtt-parser";

const MODES = {
  normal: "normal",
  pause: "pause",
};
// Connects to data-controller="video-player"
export default class extends Controller {
  mode = MODES.normal;

  static get targets() {
    return [
      "video",
      "videosource",
      "vttsource",
      "cc",
      "frame",
      "template",
      "items",
      "part",
      "form",
      "btn",
    ];
  }

  ccParts = [];
  lastPart = null;

  toggleMode() {
    if (this.mode === MODES.normal) {
      this.mode = MODES.pause;
    } else {
      this.mode = MODES.normal;
    }
  }

  play() {
    const file = this.videosourceTarget.files[0];
    this.videoTarget.src = this.convertFileToUrlBlob(file);
    this.videoTarget.classList.remove("hidden");
    this.ccTarget.classList.remove("hidden");
    this.formTarget.classList.add("hidden");
    this.videoTarget.play();
  }

  validate() {
    const file = this.videosourceTarget.files[0];
    const subs = this.vttsourceTarget.files[0];

    if (file === undefined || subs === undefined) {
      this.btnTarget.disabled = true;
      return;
    }

    this.parseSubtitles(subs, () => {
      this.btnTarget.disabled = false;
    });
  }

  tick() {
    if (this.ccParts.length === 0) return;

    let { currentTime } = this.videoTarget;
    currentTime = currentTime * 1000;
    const currentPart = this.ccParts.find((part) => {
      return currentTime >= part.from && currentTime <= part.to;
    });

    if (currentPart && currentPart !== this.lastPart) {
      this.lastPart = currentPart;

      if (this.mode === MODES.pause) {
        this.videoTarget.pause();
      }

      if (currentPart.text === "") {
        return;
      }

      this.partTargets.forEach((part) => {
        part.removeAttribute("data-active");
      });

      const domPart = document.createElement("div");
      domPart.innerHTML = currentPart.text;

      const cleanText = domPart.innerText;
      this.frameTarget.src = "/video/translate?content=" + cleanText;

      const sidePart = this.partTargets.find((part) => {
        return (
          currentTime >= part.dataset.from && currentTime <= part.dataset.to
        );
      });

      if (sidePart) {
        sidePart.scrollIntoView({
          block: "center",
          inline: "center",
          behavior: "smooth",
        });
        sidePart.setAttribute("data-active", "");
      }
    }
  }

  seek({ currentTarget }) {
    const { from } = currentTarget.dataset;
    this.videoTarget.currentTime = from / 1000;
    this.videoTarget.play();
  }

  loadSubtitles() {
    this.ccParts.forEach((part) => {
      const domPart = document.createElement("div");
      domPart.innerHTML = part.text;
      const cleanText = domPart.innerText;

      const tpl = document.createElement("div");
      tpl.classList.add(
        "p-2",
        "group",
        "flex",
        "items-center",
        "gap-3",
        "cursor-pointer",
        "hover:bg-base-300",
        "data-[active]:bg-base-300",
        "rounded-md",
      );
      tpl.setAttribute("data-video-player-target", "part");
      tpl.setAttribute("data-action", "click->video-player#seek");
      tpl.setAttribute("data-from", part.from);
      tpl.setAttribute("data-to", part.to);
      tpl.innerHTML = `
        <svg class="w-4 h-4 group-data-[active='']:opacity-100 group-hover:opacity-100 opacity-0 transition-opacity duration-300 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor">
          <path fill-rule="evenodd" d="M6.22 4.22a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06L8.94 8 6.22 5.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd"></path>
        </svg>
        <p>${cleanText}</p>
        `;

      this.itemsTarget.appendChild(tpl);
    });
  }

  convertFileToUrlBlob(file) {
    return URL.createObjectURL(file);
  }

  parseSubtitles(file, cb = null) {
    const self = this;
    const reader = new FileReader();
    reader.onload = function () {
      self.ccParts = parse(reader.result).entries;
      self.loadSubtitles();

      if (cb) cb();
    };

    reader.readAsText(file);
  }
}
