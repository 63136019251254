import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="content-manager"
export default class extends Controller {
  static get values() {
    return { content: String };
  }
  static get targets() {
    return ["definition"];
  }

  state = {
    submitting: false,
  }

  focus() {
    this.definitionTargets[this.definitionTargets.length - 1].focus()
  }

  cancel() {
    window.dispatchEvent(new CustomEvent("hidePopover"))
  }

  setDefinition(e) {
    if(this.state.submitting) {
      return;
    }

    this.state.submitting = true;
    const target = e.target;
    const new_definition = target.value;
    const old_definition = target.dataset.definition;

    if(new_definition === old_definition || new_definition === "") {
      this.state.submitting = false;
      return
    }

    const payload = {
      content: {
        new_definition,
        old_definition,
        content: this.contentValue,
      }
    }

    post("/api/v1/contents", {
      body: JSON.stringify(payload),
      responseType: "turbo-stream",
    })

    setTimeout(() => this.state.submitting = false, 1000);
  }

  setLevel(e) {
    const target = e.target.closest("[data-level]")
    const level = target.dataset.level * 1;

    const payload = {
      content: {
        content: this.contentValue,
        level
      }
    }

    post("/api/v1/contents", {
      body: JSON.stringify(payload),
      responseType: "turbo-stream",
    })
  }
}
