import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="field-manager"
export default class extends Controller {
  static get targets() {
    return ["template", "container"]
  }

  add(e) {
    this.containerTarget.insertAdjacentHTML("beforeend", this.templateTarget.innerHTML)
    e.currentTarget.scrollIntoView({ behavior: "smooth" })
    document.querySelector(".fields:last-of-type input").focus()
  }

  remove(e) {
    if(confirm("Are you sure?")) {
      e.currentTarget.closest(".fields").remove()
    }
  }
}
