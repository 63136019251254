import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="ranger"
export default class extends Controller {
  static get targets() {
    return ["label", "control"];
  }

  connect() {
    this.setLabel();
  }

  setLabel() {
    if (this.labelTarget instanceof HTMLInputElement) {
      this.labelTarget.value = this.controlTarget.value;
    } else {
      this.labelTarget.textContent = this.controlTarget.value;
    }
  }

  setControl() {
    if (this.labelTarget instanceof HTMLInputElement) {
      this.controlTarget.value = this.labelTarget.value || 0;
    } else {
      this.controlTarget.value = this.labelTarget.textContent;
    }
  }
}
