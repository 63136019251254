import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flagged-toggle"
export default class extends Controller {
  on = false

  toggle() {
    this.on = !this.on

    if(this.on) {
      this.element.classList.remove("btn-outline")
      document.body.classList.add("flagged")
    } else {
      this.element.classList.add("btn-outline")
      document.body.classList.remove("flagged")
    }
  }
}
