import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="link-invoker"
export default class extends Controller {
  connect() {
  }

  dispatch(e) {
    e.preventDefault()
    this.element.click()
  } 
}
