import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select/dist/esm/tom-select.complete"
import "tom-select/dist/esm/plugins/remove_button/plugin";
import "tom-select/dist/css/tom-select.css";

// Connects to data-controller="chips"
export default class extends Controller {
  connect() {
    this.tom = new TomSelect(this.element, {
      maxItems: 50,
      hidePlaceholder: true,
      plugins: ["remove_button"],
    });
  }

  disconnect() {
    if (this.tom) {
      this.tom.destroy();
    }
  }
}
