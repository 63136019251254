// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as Turbo from "@hotwired/turbo"

import "trix"
import "@rails/actiontext"
import TurboPower from 'turbo_power'

Turbo.StreamActions.close_drawer = function() {
  const event = new CustomEvent("close_drawer")
  window.dispatchEvent(event)
}

Turbo.StreamActions.reload_frames = function() {
  const selector = this.getAttribute("selector")
  const frames = document.querySelectorAll(selector)

  frames.forEach((frame) => { frame.reload() })
}

TurboPower.initialize(Turbo.StreamActions)

Turbo.setConfirmMethod((message) => {
  const dialog = document.getElementById("confirm-dialog")
  dialog.querySelector("p").textContent = message
  dialog.showModal()

  return new Promise((resolve) => {
    dialog.addEventListener("close", () => {
      resolve(dialog.returnValue === "confirm")
    }, { once: true })
  })
})
