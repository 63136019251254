import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="click-proxy"
export default class extends Controller {
  static get targets() { return ["target"] }

  invoke() {
    this.targetTarget.requestSubmit()
  }
}
