import {
  computePosition,
  autoPlacement,
  offset,
  shift,
  size,
} from "@floating-ui/dom";
import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="content-popover"
export default class extends Controller {
  static get targets() {
    return ["title", "word", "popover", "frame", "close"];
  }

  ignoreClick = false

  phrase({ detail }) {
    const { content, element } = detail;

    this.ignoreClick = true
    this.display(element, content)
    window.requestAnimationFrame(() => { this.ignoreClick = false })
  }

  display(target, content) {
    this.titleTarget.textContent = content;
    this.frameTarget.src = `/content?content=${content}`;

    computePosition(target, this.popoverTarget, {
      middleware: [
        size({
          apply({ elements, x, y }) {
            const isMobile = window.matchMedia("(max-width: 768px)").matches;

            if(!isMobile) {
              return
            }

            Object.assign(elements.floating.style, {
              top: `${y}px`,
              left: `${x}px`,
              bottom: "auto",
              right: "auto",
              width: "auto",
              ...(isMobile && { bottom: 0, left: 0, right: 0, top: "auto", width: "100%" })
            })
          }
        }),
        shift(),
        autoPlacement({
          allowedPlacements: [
            "top-start",
            "top-end",
            "bottom-start",
            "bottom-end",
          ],
        }),
        offset(({ rects }) => ({
          alignmentAxis: -rects.reference.width,
          mainAxis: 10,
        })),
      ],
      strategy: "fixed",
    }).then(({ x, y }) => {
      const isMobile = window.matchMedia("(max-width: 768px)").matches;

      if(!isMobile) {
        Object.assign(this.popoverTarget.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      }
    });

    this.popoverTarget.classList.remove("hidden");
  }

  show(e) {
    const target = e.currentTarget;
    let content = target.textContent;

    if(target.dataset.phrase) {
      content = target.dataset.phrase
    }

    this.display(target , content)
  }

  hide(e) {
    if(this.ignoreClick){
      return
    } 

    if(e.type === "hidePopover") {
      this.popoverTarget.classList.add("hidden");
      return
    }

    const target = e.target;

    if (
      this.popoverTarget.contains(target) &&
      target !== this.closeTarget &&
      !this.closeTarget.contains(target)
    )
      return;
    if (target === this.popoverTarget) return;
    if (target === this.frameTarget) return;
    if (target.classList.contains("word")) return;
    if (target.classList.contains("punctuation")) return;

    this.popoverTarget.classList.add("hidden");
  }
}
