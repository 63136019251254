import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="lesson-editor-line"
export default class extends Controller {
  static get targets() {
    return ["native", "target"];
  }
  async translate({ currentTarget }) {
    let { from, to } = currentTarget.dataset;
    let content = "";
    let updateElement = null;

    if (from === "native") {
      from = this.element.dataset.nativeLang;
      to = this.element.dataset.targetLang;
      content = this.nativeTarget.value;
      updateElement = this.targetTarget;
    } else {
      from = this.element.dataset.targetLang;
      to = this.element.dataset.nativeLang;
      content = this.targetTarget.value;
      updateElement = this.nativeTarget;
    }

    const resp = await post("/api/v1/translate", {
      body: JSON.stringify({ from, to, content }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const { translation } = await resp.json;
    updateElement.value = translation;
  }
}
