import { Controller } from "@hotwired/stimulus";
import Glide from "@glidejs/glide";
import { post } from "@rails/request.js";

const STATES = {
  ASKING: "asking",
  ANSWERING: "answering",
  SUBMITTING: "submitting",
};

export default class extends Controller {
  _glide = null;
  _state = STATES.ASKING;
  _cards = 10;
  _flashcard = null;
  _handle = null

  static get targets() {
    return ["flashcard", "answer", "reveal"];
  }

  static get values() {
    return {
      id: Number,
    }
  }

  get state() {
    return this._state;
  }

  set state(newState) {
    this._state = newState;
    this.element.setAttribute("data-state", newState);
  }

  keypress(e) {
    if(this._handle || this.state === STATES.SUBMITTING) return

    this._handle = setTimeout(() => {
      this._handle = null
    }, 200)

    const answerCodes = ["Digit1", "Digit2", "Digit3", "Digit4"]

    if(e.code === "Space" && this.state === STATES.ASKING){
      this.flip()
    } else if(answerCodes.includes(e.code) && this.state === STATES.ANSWERING){
      const answer = e.code.replace("Digit", "")
      const answerButton = document.querySelectorAll("[data-score]")[answer - 1]

      answerButton.click()
    }
  }

  flashcardTargetConnected(e) {
    if (this._glide) {
      this._glide.update();
      this._glide.go(">");
    }

    this.state = STATES.ASKING;
    this._flashcard = e
    this.disable();
  }

  connect() {
    this._glide = new Glide(".glide", {
      swipeThreshold: false,
      dragThreshold: false,
    }).mount();
  }

  disconnect() {
    this._glide.destroy();

    if(this._handle) {
      clearTimeout(this._handle)
    }
  }

  flip(e) {
    if(e) { e.preventDefault(); }

    if(this._flashcard) {
      this._flashcard.classList.toggle("flipped")
      this.state = STATES.ANSWERING;
      this.enable();
    }
  }

  disable() {
    this.answerTargets.forEach((el) => (el.disabled = true));
    this.revealTarget.disabled = false;
  }

  enable() {
    this.answerTargets.forEach((el) => (el.disabled = false));
    this.revealTarget.disabled = true;
  }

  async answer(e) {
    const score = e.currentTarget.dataset.score;

    const payload = {
      score: score * 1,
      session_id: this.idValue,
      card_id: this._flashcard.dataset.id * 1,
    };

    this.state = STATES.SUBMITTING;
    this.disable();

    await post("/api/v1/answers", {
      body: JSON.stringify(payload),
      contentType: "application/json",
      responseKind: "turbo-stream",
    });
  }
}
