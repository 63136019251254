import { Controller } from "@hotwired/stimulus";
import WaveSurfer from "wavesurfer.js";

// Connects to data-controller="audio-player"
export default class extends Controller {
  static get targets() {
    return ["audio", "visualizer"];
  }

  audioMotion = null;

  connect() {
    if (this.hasVisualizerTarget) {
      this.initVisualizer();
    }
  }

  disconnect() {
    if (this.waveSurfer) {
      this.waveSurfer.destroy();
    }
  }

  play({ target }) {
    const btn = target.closest("button");
    const { src } = btn.dataset;

    if (this.waveSurfer) {
      this.waveSurfer.playPause();
      return;
    }

    this.audioTarget.src = src;
    this.audioTarget.pause();
    this.audioTarget.play();
  }

  initVisualizer() {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Define the waveform gradient
    const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height * 1.35);
    gradient.addColorStop(0, "#656666"); // Top color
    gradient.addColorStop((canvas.height * 0.7) / canvas.height, "#656666"); // Top color
    gradient.addColorStop((canvas.height * 0.7 + 1) / canvas.height, "#ffffff"); // White line
    gradient.addColorStop((canvas.height * 0.7 + 2) / canvas.height, "#ffffff"); // White line
    gradient.addColorStop((canvas.height * 0.7 + 3) / canvas.height, "#B1B1B1"); // Bottom color
    gradient.addColorStop(1, "#B1B1B1"); // Bottom color

    // Define the progress gradient
    const progressGradient = ctx.createLinearGradient(
      0,
      0,
      0,
      canvas.height * 1.35,
    );
    progressGradient.addColorStop(0, "#EE772F"); // Top color
    progressGradient.addColorStop(
      (canvas.height * 0.7) / canvas.height,
      "#EB4926",
    ); // Top color
    progressGradient.addColorStop(
      (canvas.height * 0.7 + 1) / canvas.height,
      "#ffffff",
    ); // White line
    progressGradient.addColorStop(
      (canvas.height * 0.7 + 2) / canvas.height,
      "#ffffff",
    ); // White line
    progressGradient.addColorStop(
      (canvas.height * 0.7 + 3) / canvas.height,
      "#F6B094",
    ); // Bottom color
    progressGradient.addColorStop(1, "#F6B094"); // Bottom color

    this.waveSurfer = WaveSurfer.create({
      container: this.visualizerTarget,
      waveColor: "#383351",
      progressColor: "#4F4A85",
      barWidth: 7,
      barGrap: 1,
      barRadius: 9,
      autoPlay: true,
      media: this.audioTarget,
      responsive: true,
    });

    this.waveSurfer.on("interaction", () => {
      this.waveSurfer.playPause();
    });

    this.waveSurfer.on("ready", () => {
      this.waveSurfer.play();
    });
  }
}
