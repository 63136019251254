import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sentence-builder"
export default class extends Controller {
  static get targets() {
    return ["word", "button"];
  }

  nextExpectedWordElement() {
    return this.wordTargets.find((word) => {
      return word.getAttribute("data-selected") === "false";
    });
  }

  select({ target }) {
    const { word } = target.dataset;
    let nextExpectedWordEl = this.nextExpectedWordElement();

    if (nextExpectedWordEl && nextExpectedWordEl.dataset.word === word) {
      nextExpectedWordEl.setAttribute("data-selected", true);
      target.classList.toggle("btn-disabled");
    }

    nextExpectedWordEl = this.nextExpectedWordElement();

    if(!nextExpectedWordEl) {
      this.buttonTarget.click();
    }
  }
}
