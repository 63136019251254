import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="content-highlighter"
export default class extends Controller {
  static get targets() {
    return ["word"];
  }

  startElement = null;
  endElement = null;
  highlighting = false;
  content = "";

  start({ target }) {
    this.content = "";

    if (target instanceof HTMLSpanElement) {
      this.highlighting = true;
      this.startElement = target;
    }
  }

  end({ target, touches }) {
    if (this.highlighting == false) return;

    if(touches && touches.length > 0) {
      target = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY);
    }

    this.highlighting = false;
    if (target instanceof HTMLSpanElement) {
      this.endElement = target;
    }

    this.highlight({ target });

    if (this.content.length > 0) {
      const event = new CustomEvent("phrase", {
        detail: {
          content: this.content,
          element: this.endElement,
        },
      });

      window.dispatchEvent(event);
    }
  }

  highlight(e) {
    let target = e.target;

    if(e instanceof TouchEvent) {
      target = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY);
    }

    if (!this.highlighting) return;
    if (this.startElement == target) return;

    if (target instanceof HTMLSpanElement) {
      this.endElement = target;
    }

    if (this.startElement && this.endElement) {
      let startIndex = this.startElement.dataset.index * 1;
      let endIndex = this.endElement.dataset.index * 1;

      if (startIndex > endIndex) {
        const tmp = startIndex;
        startIndex = endIndex;
        endIndex = tmp;
      }

      let content = "";

      this.wordTargets.forEach((word, index) => {
        word.classList.remove("highlight", "highlight-start", "highlight-end");

        if (index < startIndex || index > endIndex) {
          return;
        }

        let spacer = word.dataset.type === "word" ? " " : "";
        content += spacer + word.innerText.trim();
        word.classList.add("highlight");

        if (index === startIndex) {
          word.classList.add("highlight-start");
        }

        if (index === endIndex) {
          word.classList.add("highlight-end");
        }
      });

      this.content = content.trim();
    }
  }
}
