import { Controller } from "@hotwired/stimulus";
import { parse } from "@plussub/srt-vtt-parser";

const MODES = {
  normal: "normal",
  pause: "pause",
};
// Connects to data-controller="youtube-player"
export default class extends Controller {
  static get targets() {
    return ["video", "frame", "vttsource", "items", "part"];
  }

  mode = MODES.normal;
  ccParts = [];
  lastPart = null;
  player = null;

  connect() {
    this.includeScript();
    this.parseSubtitles();
  }

  disconnect() {
    clearTimeout(this._tick);
  }

  includeScript() {
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);
  }

  toggleMode() {
    if (this.mode === MODES.normal) {
      this.mode = MODES.pause;
    } else {
      this.mode = MODES.normal;
    }
  }

  bind() {
    const self = this;

    this.player = new YT.Player(this.videoTarget, {
      events: {
        onStateChange: function ({ data: status }) {
          if (status == 1) {
            self.tick();
          } else {
            clearTimeout(self._tick);
          }
        },
      },
    });
  }

  tick() {
    if (this.ccParts.length === 0) return;
    if (!this.player) return;

    let currentTime = this.player.getCurrentTime();
    currentTime = currentTime * 1000;
    const currentPart = this.ccParts.find((part) => {
      return currentTime >= part.from && currentTime <= part.to;
    });

    if (currentPart && currentPart !== this.lastPart) {
      this.lastPart = currentPart;

      if (this.mode === MODES.pause) {
        this.player.pauseVideo();
      }

      if (currentPart.text === "") {
        return;
      }

      this.partTargets.forEach((part) => {
        part.removeAttribute("data-active");
      });

      const domPart = document.createElement("div");
      domPart.innerHTML = currentPart.text;

      const cleanText = domPart.innerText;
      this.frameTarget.src = "/video/translate?content=" + cleanText;

      const sidePart = this.partTargets.find((part) => {
        return (
          currentTime >= part.dataset.from * 1 &&
          currentTime <= part.dataset.to * 1
        );
      });

      if (sidePart) {
        sidePart.scrollIntoView({
          block: "center",
          inline: "center",
          behavior: "smooth",
        });
        sidePart.setAttribute("data-active", "");
      }
    }

    this._tick = setTimeout(() => {
      this.tick();
    }, 10);
  }

  seek({ currentTarget }) {
    const { from } = currentTarget.dataset;

    if(!this.player) return;

    this.player.seekTo(from / 1000);
    this.player.playVideo();
  }

  loadSubtitles() {
    this.ccParts.forEach((part) => {
      const domPart = document.createElement("div");
      domPart.innerHTML = part.text;
      const cleanText = domPart.innerText;

      const tpl = document.createElement("div");
      tpl.classList.add(
        "p-2",
        "group",
        "flex",
        "items-center",
        "gap-3",
        "cursor-pointer",
        "hover:bg-base-300",
        "data-[active]:bg-base-300",
        "rounded-md",
      );
      tpl.setAttribute("data-youtube-player-target", "part");
      tpl.setAttribute("data-action", "click->youtube-player#seek");
      tpl.setAttribute("data-from", part.from);
      tpl.setAttribute("data-to", part.to);
      tpl.innerHTML = `
        <svg class="w-4 h-4 group-data-[active='']:opacity-100 group-hover:opacity-100 opacity-0 transition-opacity duration-300 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor">
          <path fill-rule="evenodd" d="M6.22 4.22a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06L8.94 8 6.22 5.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd"></path>
        </svg>
        <p>${cleanText}</p>
        `;

      this.itemsTarget.appendChild(tpl);
    });
  }

  parseSubtitles(cb = null) {
    this.ccParts = parse(this.vttsourceTarget.value).entries;
    this.loadSubtitles();
    if (cb) cb();
  }
}
