import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="blur-content"
export default class extends Controller {
  static get targets() {
    return ["on", "off"];
  }

  state = {
    on: true,
  };

  toggle() {
    console.log(this.state);
    this.state.on = !this.state.on;

    this.updateUX();
  }

  updateUX() {
    if (this.state.on) {
      this.element.setAttribute("data-blur", "on");
    } else {
      this.element.removeAttribute("data-blur");
    }

    if (!this.hasOnTarget || !this.hasOffTarget) return;
    if (this.state.on) {
      this.onTarget.classList.remove("hidden");
      this.offTarget.classList.add("hidden");
    } else {
      this.onTarget.classList.add("hidden");
      this.offTarget.classList.remove("hidden");
    }
  }
}
