import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="autohighlight"
export default class extends Controller {
  connect() {
    this.element.addEventListener("focus", this.highlight.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("focus", this.highlight.bind(this));
  }

  highlight() {
    this.element.select();
  }
}
