import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="lesson-editor"
export default class extends Controller {
  static get targets() {
    return ["audio", "timer", "line"];
  }

  _start_time = 0;
  _end_time = 0;
  _last_play_button = null;
  _index = 0;

  lineTargetConnected(line) {
    line.querySelectorAll("input[data-index=true]").forEach((input) => {
      input.setAttribute("tabindex", ++this._index);
    });
  }

  play({ currentTarget }) {
    if (this._last_play_button) {
      this._last_play_button.classList.remove("btn-primary");
      this._last_play_button.classList.add("btn-neutral");
    }

    const start =
      currentTarget.closest(".fields").querySelector("[data-start]").value * 1;
    const end =
      currentTarget.closest(".fields").querySelector("[data-end]").value * 1;

    this._start_time = start;
    this._end_time = end;

    this.audioTarget.currentTime = start;
    this.audioTarget.play();

    this._last_play_button = currentTarget;
    this._last_play_button.classList.add("btn-primary");
    this._last_play_button.classList.remove("btn-neutral");
  }

  copyTimer({ currentTarget }) {
    this.audioTarget.pause();

    currentTarget.classList.remove("btn-dark");
    currentTarget.classList.add("btn-primary");
    this.timerTarget.textContent = "Copied!";

    navigator.clipboard.writeText(this.audioTarget.currentTime.toFixed(2));

    setTimeout(() => {
      currentTarget.classList.add("btn-dark");
      currentTarget.classList.remove("btn-primary");
      this.timerTarget.textContent = this.audioTarget.currentTime.toFixed(2);
    }, 200);
  }

  tick() {
    const current_time = this.audioTarget.currentTime;
    this.timerTarget.textContent = current_time.toFixed(2);

    if (this._end_time > 0 && current_time > this._end_time) {
      this.audioTarget.pause();
      this._end_time = 0;
      this._last_play_button.classList.remove("btn-primary");
      this._last_play_button.classList.add("btn-neutral");
    }
  }
}
