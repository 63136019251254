import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

import Sortable from "stimulus-sortable"
application.register("sortable", Sortable)

export { application }
