import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="lesson-player"
export default class extends Controller {
  static get targets() {
    return ["audio", "line", "wrapper"];
  }

  pauseAfterLine = false
  lastLine = null

  get lines() {
    return this.lineTargets.map((line) => {
      return {
        element: line,
        start: line.dataset.start * 1,
        end: line.dataset.end * 1,
      };
    });
  }

  toggleMode() {
    this.pauseAfterLine = !this.pauseAfterLine
  }

  seek({ currentTarget: line }) {
    const { start } = line.dataset;

    this.lastLine = null
    this.audioTarget.currentTime = start * 1;
    this.audioTarget.play();
  }

  ended() {
    this.lineTargets.forEach((line) => line.classList.remove("active"));
  }

  toggle({ currentTarget: btn }) {
    const { col } = btn.dataset;
    this.wrapperTarget.setAttribute("data-col", col);
  }

  tick() {
    const { currentTime: time } = this.audioTarget;

    const activeLine = this.lines.find((line) => {
      return line.start <= time && time <= line.end;
    });

    if (activeLine) {
      if(this.pauseAfterLine && this.lastLine && this.lastLine !== activeLine.element) {
        this.lastLine = activeLine.element
        this.audioTarget.pause()
        return
      }

      if(this.audioTarget.paused) {
        return
      }

      this.lineTargets.forEach((line) => line.classList.remove("active"));
      activeLine.element.classList.add("active");
      this.lastLine = activeLine.element
    }
  }
}
