import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="drawer"
export default class extends Controller {
  static get targets() {
    return ["toggle"]
  }

  connect() {
    window.requestAnimationFrame(() => {
      this.toggleTarget.checked = this.element.hasAttribute("data-open")
    })
  }

  disconnect() {
    this.close()
    this.element.remove()
  }

  close() {
    this.toggleTarget.checked = false
  }
}
