import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slovene-keyboard"
export default class extends Controller {
  static get targets() { return ["input", "lcase", "ucase"] }

  handle = null
  KEYS = {
    c: "č",
    s: "š",
    z: "ž",
  }

  addC(e) {
    e.preventDefault()
    this.insertChar(this.KEYS.c, e.shiftKey)
  }

  addS(e) {
    e.preventDefault()
    this.insertChar(this.KEYS.s, e.shiftKey)
  }

  addZ(e) {
    e.preventDefault()
    this.insertChar(this.KEYS.z, e.shiftKey)
  }

  add(e) {
    const btn = e.target.closest("button")
    const char = btn.dataset.char

    this.insertChar(char, e.shiftKey)
  }

  insertChar(char, uppercase) {
    if(uppercase) { char = char.toUpperCase() }
    const start = this.inputTarget.selectionStart
    const end = this.inputTarget.selectionEnd
    const text = this.inputTarget.value
    const before = text.substring(0, start)
    const after  = text.substring(end, text.length)
    this.inputTarget.value = (before + char + after)
    this.inputTarget.selectionStart = start + 1
    this.inputTarget.selectionEnd = start + 1

    this.inputTarget.focus()
  }

  ucase(){
    this.lcaseTargets.forEach((el) => {
      el.classList.add("hidden")
    })

    this.ucaseTargets.forEach((el) => {
      el.classList.remove("hidden")
    })
  }

  lcase() {
    this.ucaseTargets.forEach((el) => {
      el.classList.add("hidden")
    })

    this.lcaseTargets.forEach((el) => {
      el.classList.remove("hidden")
    })
  }

  toggle() {
    if(this.handle) {
      clearTimeout(this.handle)
    }

    this.ucase()
    this.handle = setTimeout(this.lcase.bind(this), 300)
  }
}
