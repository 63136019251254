import { Controller } from "@hotwired/stimulus"
import ace from "ace-builds"
import workerHtmlUrl from "ace-builds/src-noconflict/worker-html?url";
ace.config.setModuleUrl("ace/mode/html_worker", workerHtmlUrl)
import { Mode as HtmlMode } from "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-monokai"

// Connects to data-controller="ace-editor"
export default class extends Controller {
  _editor = null

  static get targets() {
    return ["value", "editor"]
  }

  connect() {
    this._editor = ace.edit(this.editorTarget, {
      theme: "ace/theme/monokai",
    })
    this._editor.session.setMode(new HtmlMode({ fragmentContext: "body" }))

    this._editor.session.on("change", () => {
      this.valueTarget.value = this._editor.getValue()
    })
  }

  disconnect() {
    if(this._editor) {
      this._editor.destroy()
      this._editor = null
    }
  }
}
