import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toast"
export default class extends Controller {
  static get values() {
    return {
      duration: { type: Number, default: 4000 }
    }
  }

  _handle = null

  connect() {
    this._handle = setTimeout(this.animateOut.bind(this), this.durationValue)
  }

  disconnect() {
    this.dispose()
  }

  animateOut() {
    this.element.classList.add("slideOut")

    if(this._handle) {
      clearTimeout(this._handle)
    }
  }

  dispose(e) {
    if(e && e.animationName !== "slideOut") return

    if(this.element) {
      this.element.remove()
    }
  }
}
